import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as myConstList from "./BaseUrl";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import ChangeLang from "./ChangeLang";
import {
  isMobile,
  isDesktop,
  isAndroid,
  isIOS,
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";
import LoginPin from "../Common/LoginPin"
import authService from "../../services/auth.service";

const baseUrl = myConstList.baseUrl;

export default function ExchangeKycHeader(props) {
  const userData = (localStorage.getItem("userData")) ? JSON.parse(localStorage.getItem("userData")) : null;
 // console.log(userData);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [isDepositModelOpen, setisDepositModelOpen] = useState(false);
  const [kycStatus, setkycStatus] = useState("N");
  const [device, setDevice] = useState("");
  const [email, setEmail] = useState("");
  const [mobile_no, setMobile_no] = useState("");
  const [user, setUser] = useState("Guest");
  const [countryName, setCountryName] = useState("");
  const [cityName, setCityName] = useState("");
  const [checked, setchecked] = useState(true);
  const [LofinInsert1, setLofinInsert1] = useState(false);
  const [usdtPortfolio, setUsdtPortfolio] = useState(0);
  const [inrPortfolio, setInrPortfolio] = useState(0);
  const [accHolder, setAccHolder] = useState("");
  const [activeTab, setActiveTab] = useState("");
  const [pair, setPair] = useState(localStorage.getItem("excoin1") ? localStorage.getItem("excoin1") + "/" + localStorage.getItem("excoin2") : null);

  useEffect(() => {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", true);
      localStorage.setItem("theamName", "dark");
    }
    const token = localStorage.getItem("token");
    if (token) {
      GetUserData();
    }
    // portfolioData();
    getGeoInfo();
    DeviceName();
    getOpenPair();
    var theme = localStorage.getItem("theme");
    if (theme == null || theme == "null") {
      theme = false;
    }
    if (theme == "true" || theme == "false") {
      theme = JSON.parse(theme);
      handleChange(theme);
    }
    if (LofinInsert1 == true) {
      LoginInert();
    }
  }, []);

  useEffect(() => {
    if (props.portfolioData && props.portfolioData.data.length > 0) {
      setUsdtPortfolio(props.portfolioData && props.portfolioData.data[0].USDT);
    }
  }, [props.portfolioData]);

  const getOpenPair = () => {
    if(!localStorage.getItem("excoin1")){
    axios
      .get(baseUrl + "/api/get-open-pair")
      .then((res) => {
        if (res.data.status) {
          setPair(
            `${res.data.data[0].first_name}/${res.data.data[0].second_name}`
          );
        }
      })
      .catch((err) => { });
    }
  };

  const GetUserData = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        if (resp.data[0].enable_2fa != "N" || resp.data.enable_2fa != "") {
          setLofinInsert1(true);
        }
        localStorage.setItem("userData", JSON.stringify(resp.data[0]));
        setEmail(resp.data[0].email);
        setAccHolder(resp.data[0].AccHolder);
        setMobile_no(resp.data[0].mobile_no);
        if (resp.data[0].nickname !== "null") {
          setUser(resp.data[0].nickname);
        }
        if (resp.data[0].nickname == "null" || resp.data[0].nickname == null) {
          setUser("Guest");
        }

        var token = localStorage.getItem("token");
        if (token != null) {
          if (
            resp.data[0].kycVerification_status === "N" ||
            resp.data[0].kycVerification_status === "null"
          ) {
          }
          setkycStatus(resp.data[0].kycVerification_status);
          if (resp.data[0].kycVerification_status === "Y") {
            setisDepositModelOpen(false);
          } else {
            setisDepositModelOpen(true);
          }
        }
      } else {
        if (
          resp.key === "logout" &&
          localStorage.getItem("token") !== "null" &&
          localStorage.getItem("token") !== null
        ) {
          localStorage.clear();
          sessionStorage.clear();
          setTimeout(
            () => (window.location.href = "/login-to-buy-sell-crypto"),
            2000
          );
        }
      }
    });
    axios
      .get(baseUrl + "/api/crypto-data-by-tab")
      .then((res) => {
        // console.log("crypto-data");
        //console.log(res.data);
        let data = res.data;
        let tab;
        for (var i = 0; i < data.length; i++) {
          if (data[i].short_name == "USDT") {
            setActiveTab(data[i].short_name);
            tab = data[i].short_name;
            break;
          } else {
            setActiveTab(data[0].short_name);
            tab = data[0].short_name;
          }
        }
      })
      .catch();
  };

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;

        setCountryName(data.country_name);
        setCountryName(data.city);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DeviceName = () => {
    if (isMobile) {
      setDevice("Mobile");
    }
    if (isAndroid) {
      setDevice("Android");
    }
    if (isIOS) {
      setDevice("IOS");
    }
    if (isDesktop) {
      setDevice("Desktop");
    }
  };

  const LoginInert = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    var userIdDataIp = {
      ip: localStorage.getItem("ip"),
      device: device,
      browser: browserName + " " + browserVersion,
      osName: osName + " " + osVersion,
      cityName: cityName,
      countryName: countryName,
    };
    if (LofinInsert1 == false) {
      return;
    }
    async function getLoginInsert() {
      await axios.post(
        baseUrl + "/frontapi/login-insert",
        userIdDataIp,
        config
      );
    }
    getLoginInsert();
  };

  const showHeaderPart = () => {
    var token = localStorage.getItem("token");

    if (token === null) {
      return (
        <ul className="">
          <li className="">
            <Link to="/login-to-buy-sell-crypto">{t("Login")}</Link>
          </li>
          <li className="">
            <Link to="/register-for-bitcoin-exchange">{t("sign_up")}</Link>
          </li>
        </ul>
      );
    } else {
      return (
        <ul className="">

          <li className="renone">{t("Portfolio Value")}:</li>
          {/* <ToastContainer position="left" /> */}
          <li className="renone">
            <table>
              <tbody>
                <tr>
                  <div className="amount-inner d-flex">
                    <div className="amount-right ml-2">
                      <p className="mb-3">
                        <b> {parseFloat(usdtPortfolio)}</b> USDT
                      </p>
                    </div>
                  </div>
                </tr>
              </tbody>
            </table>
          </li>

          <li className="">
            <div className="dropdown user_dropdown">
              <span
                type="button"
                className=" dropdown-toggle d-flex align-items-center"
                data-toggle="dropdown"
              >
                <i className="fa fa-user-circle mr-1"></i>{" "}
                <span className="name_max_w">{accHolder ? accHolder : email ? email : mobile_no}</span>
                {/* {localStorage.getItem("email")} */}
              </span>
              <div className="dropdown-menu2">
                {/*console.log("exchange kycStatus=> ",kycStatus)*/}
                <Link className="a_flex" to="/profile">
                  <i className="fa fa-user-circle  "></i> {t("Profile")}
                </Link>
                <Link className="a_flex" to="/Kyc">
                  <i className="fa fa-id-card-o"></i> {t("My KYC")} {(kycStatus == "Y") ? <i class="fa fa-check ml-3 text-success" aria-hidden="true"></i> : ""}
                </Link>
                <Link className="a_flex" to="/help">
                  <i className="fa fa-headphones"></i> {t("Support")}
                </Link>
                <Link to="#" className="a_flex" onClick={logout}>
                  <i className="fa fa-sign-out"></i>{t("Logout")}
                </Link>
              </div>
            </div>
          </li>
        </ul>
      );
    }
  };

  const logout = () => {
    authService.logout();
    toast.success(t("Logout Successfully"));
    history.push("/");
    // let interval = setInterval(() => {
    //   var theme = localStorage.getItem("theme");
    //   localStorage.clear();
    //   sessionStorage.clear();
    //   localStorage.setItem("theme", theme);
    //   history.push("/login-to-buy-sell-crypto");
    //   clearInterval(interval);
    // }, 1000);
  };

  const handleChange = (checked) => {
    let tags = document.getElementsByTagName("body");
    if (checked === true) {
      tags[0].classList.add("dark-theme");
      props.graphColor(false);
      setchecked(false);
      localStorage.setItem("theamName", "dark");
    } else {
      tags[0].classList.remove("dark-theme");
      props.graphColor(true);
      setchecked(true);
      localStorage.setItem("theamName", "light");
    }
    localStorage.setItem("theme", checked);
  };

  var token = localStorage.getItem("token");
  return (
    <div className="">
      <LoginPin />
      <nav className="navbar navbar-expand-lg nav-scroll2">
        <div className="container-fluid ">
          <Link className="navbar-brand" to="/index">
            <img
              src="/assets/img/logo.png"
              alt="header-Logo"
              className="logo logo1"
            />
            <img
              src="/assets/img/logo-light.png"
              alt="header-Logo"
              className="logo logo2"
            />
          </Link>

          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav line align-items-center">
              <li className="nav-item">
                {!token ? (
                  <a className={`nav-link ${location.pathname == "/exchange/" + pair ? "active" : ""}`} href={`/exchange/${pair}`}>
                    {t("Exchange")}
                  </a>
                ) : (
                  <a className={`nav-link ${location.pathname == "/exchange/" + pair ? "active" : ""}`} href={`/exchange/${pair}`}>
                    {t("Exchange")}
                  </a>
                )}
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${location.pathname == "/account" ? "active" : ""
                    }`}
                  to="/account"
                >{t("Account")}

                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link " to="/transactions">
                  {t("Transactions")}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link " to="/trade">
                  {t("Trade")}
                </Link>
              </li>

             
              <li><Link
                className={`nav-link ${location.pathname == "/coinExchange" ? "active" : ""
                  }`}
                to="/coinExchange"
              >
                {t("Sale market")} &#8595;%
              </Link>
              </li>
              {/* <li>
              <a className={`nav-link ${location.pathname == "/listing/" + pair ? "active" : ""}`} href={`/listing/${pair}`}>
                    {t("Listing")}
                  </a>
              </li> */}
              <li><ChangeLang /></li>
              <li className="nav-item">
                {checked == false ? (
                  <img
                    src="/assets/img/sun.png"
                    width="25px"
                    alt="sun"
                    className="sun-img theme-img"
                    onClick={() => handleChange(checked)}
                    checked={checked}
                  />
                ) : (
                  <img
                    src="/assets/img/moon.png"
                    width="25px"
                    alt="moon"
                    className="moon-img theme-img"
                    onClick={() => handleChange(checked)}
                    checked={checked}
                  />
                )}
              </li>

            </ul>
          </div>

          <div className="dashbord_menu">{showHeaderPart()}</div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
          >
            <span className="icon-bar">
              <i className="fa fa-bars fa-2x"></i>
            </span>
          </button>
        </div>
      </nav>
    </div>
  );
}
