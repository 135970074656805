import React, { useEffect, useState } from "react";
import MainHeader from "./Common/MainHeader";
import Footer from "./Common/Footer";
import DocumentMeta from "react-document-meta";
import { ProductName } from "./Common/ProductName";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { baseUrl } from "./Common/BaseUrl";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import { useHistory, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { cryptoCoinList } from "./services/WhiteListServices";

const Send = () => {
  const params = useParams();
  const [activeTab, setActiveTab] = useState("Email");
  const [mainActiveTab, setMainActiveTab] = useState("send_to");
  const [inputVal, setInputVal] = useState("");
  const [amount, setAmount] = useState("");
  const [disableAmountBtn, setDisableAmountBtn] = useState(true);
  const [balanceResp, setBalanceResp] = useState("");
  const [selectedWallet, setSelectedWallet] = useState("wallet");
  const [selectedInputType, setSelectedInputType] = useState("");
  const [is2FaEnable, setIs2FaEnable] = useState(false);
  const [is2FaModelOpen, setIs2FaModelOpen] = useState(false);
  const [vcode, setVcode] = useState("");
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");
  const [isOtpModelOpen, setIsOtpModelOpen] = useState(false);
  const [c2cOtp, setC2cOtp] = useState("");
  const [otpBtnDisabled, setOtpBtnDisabled] = useState(false);
  const [coinListData, setCoinListData] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState("");
  const [selectedCoinName, setSelectedCoinName] = useState("");
  const [isKycEnable, setIsKycEnable] = useState(true);
  const [remark, setRemark] = useState("");
  const history = useHistory();

  useEffect(() => {
    getUserDetails();
    CryptoCoinData();
    var token1 = localStorage.getItem("token");
    if (token1 === null) {
      return history.push("/login-to-buy-sell-crypto");
    }
    if (params.data) {
      console.log("params", params);
      const decodedData = decodeURIComponent(params.data);
      const decryptedBytes = CryptoJS.AES.decrypt(
        decodedData,
        "abcdefghijklmnopqrstuvwxyz1234567890"
      );
      const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
      const jsonDecode = JSON.parse(decryptedData);
      console.log("jsonDecode", jsonDecode.payId);
      handleTabChange("PayID");
      setInputVal(jsonDecode.payId);
      setAmount(jsonDecode.amount);
      setDisableAmountBtn(false);
    }
  }, []);

  const getUserDetails = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        if (resp.data[0].enable_2fa === "Y") {
          setIs2FaEnable(true);
        } else {
          setIs2FaEnable(false);
        }

        if (resp.data[0].kycVerification_status === "Y") {
          setIsKycEnable(false);
        } else {
          setIs2FaEnable(true);
        }
      }
      if (
        resp.key === "logout" &&
        localStorage.getItem("token") !== "null" &&
        localStorage.getItem("token") !== null
      ) {
        localStorage.clear();
        setTimeout(
          () => (window.location.href = "/login-to-buy-sell-crypto"),
          2000
        );
      }
    });
  };

  const nextStep = (tabName, inputType) => {
    if (isKycEnable === true) {
      toast.error("Please complete kyc first");
      return false;
    }

    if (!inputVal) {
      toast.error("Input filed is require");
      return false;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    const data = {
      inputVal: inputVal,
      type: inputType,
    };

    axios
      .post(baseUrl + "/frontapi/validate-input", data, config)
      .then((res) => {
        const resp = res.data;
        if (resp.status === false) {
          toast.error(resp.message);
        } else {
          if (resp.data.kycVerification_status === "Y") {
            setSelectedInputType(inputType);
            console.log("calling");
            setMainActiveTab(tabName);
          } else {
            toast.error("Reciver KYC is pending");
          }
        }
      });
  };

  const nextStepChange = (tabName) => {
    console.log("amount", amount);
    if (tabName === "confirm_payment") {
      if (!selectedCoin || selectedCoin === "select") {
        toast.error("Please select currency");
        return;
      }
    }
    if (!amount || amount == 0 || amount < 0) {
      toast.error("Please enter valid amount");
      return false;
    }
    setMainActiveTab(tabName);
  };

  const handleTabChange = (tabName) => {
    setInputVal("");
    setActiveTab(tabName);
  };

  const getUserBalance = (value) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    const data = {
      coinId: value,
    };

    axios
      .post(baseUrl + "/frontapi/get-transfer-balance", data, config)
      .then((res) => {
        const resp = res.data;
        if (resp.status === true) {
          setBalanceResp(resp.data);
        }
      });
  };

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setInputVal(value);
    }
    if (name === "phone") {
      setInputVal(value);
    }
    if (name === "payId") {
      setInputVal(value);
    }
    if (name === "amount") {
      const regex = /^\d{1,8}(\.\d{0,8})?$/;

      if (regex.test(value)) {
        setAmount(value);
        setDisableAmountBtn(false);
      } else {
        // Display an error or handle the invalid input in some way
        console.log("Invalid amount format");
        // You might want to set an error state or show a message to the user
      }
    }

    if (name === "wallet_select") {
      setSelectedWallet(value);
    }

    if (name === "vcode") {
      setVcode(value);
    }
    if (name === "c2cOtp") {
      setC2cOtp(value);
    }

    if (name === "remark") {
      setRemark(value);
    }

    if (name === "coinType") {
      setSelectedCoin(value);
      const selectedCoinObject = coinListData.find((coin) => coin.id == value);
      console.log("selectedCoinObject", selectedCoinObject, coinListData);
      setSelectedCoinName(
        selectedCoinObject ? selectedCoinObject.short_name : ""
      );
      getUserBalance(value);
    }
  };

  const confirmPay = () => {
    confirmAlert({
      title: "Confirm to submit",
      message: `Confirm you want to send`,
      buttons: [
        {
          label: "Yes",
          onClick: () => finalPayment(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const checkAuthentication = () => {
    if (is2FaEnable === true) {
      setIs2FaModelOpen(true);
    } else {
      sendOtp();
    }
  };

  const finalPayment = () => {
    if (amount === 0) {
      toast.send("Please add amount");
      return false;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const dataObj = {
      amount: amount,
      inputVal: inputVal,
      inputType: selectedInputType,
      walletType: selectedWallet,
      coinId: selectedCoin,
      remark: remark,
    };

    axios
      .post(baseUrl + "/frontapi/transfer-payment", dataObj, config)
      .then((res) => {
        const resp = res.data;
        if (resp.status === true) {
          toast.success(resp.message);
          setSelectedInputType("");
          setInputVal("");
          setSelectedWallet("wallet");
          setAmount("");
          setMainActiveTab("send_to");
          setActiveTab("Email");
          setTimeout(() => {
            window.location.href = "/transfer";
          }, 1000);
        } else {
          getUserBalance(selectedCoin);
          toast.error(resp.message);
        }
      });
  };

  const closeModal = () => {
    setIs2FaModelOpen(false);
    setIsOtpModelOpen(false);
  };

  const verifyCode = async (e) => {
    e.preventDefault();
    if (!vcode) {
      setMessage("This field is required");
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const data = {
      code: vcode,
    };
    try {
      axios
        .post(baseUrl + "/frontapi/verifyPayCode", data, config)
        .then((res) => {
          const resp = res.data;
          setMessage(resp.message);
          if (resp.status === true) {
            sendOtp();
            setIs2FaModelOpen(false);
            setVcode("");
          } else {
            setColor("red");
          }
        });
    } catch (error) {}
  };

  const sendOtp = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    const dataObj = {
      amount: amount,
      inputVal: inputVal,
      inputType: selectedInputType,
    };

    axios
      .post(baseUrl + "/frontapi/send-c2c-otp", dataObj, config)
      .then((res) => {
        const resp = res.data;
        if (resp.status === true) {
          toast.success(resp.message);
          setIsOtpModelOpen(true);
        } else {
          toast.error(resp.message);
        }
      });
  };

  const verifyC2CCode = () => {
    if (!c2cOtp) {
      setMessage("This field is required");
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const data = {
      code: c2cOtp,
      amount: amount,
      inputVal: inputVal,
      inputType: selectedInputType,
    };
    setOtpBtnDisabled(true);
    try {
      axios
        .post(baseUrl + "/frontapi/verify-c2c-code", data, config)
        .then((res) => {
          const resp = res.data;
          setMessage(resp.message);
          if (resp.status === true) {
            toast.success(resp.message);
            setIsOtpModelOpen(false);
            setTimeout(() => {
              history.push("/transfer");
            }, 1000);
            // setOtpBtnDisabled(false)
          } else {
            toast.error(resp.message);
            setOtpBtnDisabled(false);
          }
        });
    } catch (error) {}
  };

  const handleKeyPress = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const CryptoCoinData = async () => {
    axios.get(baseUrl + "/frontapi/cryptocoin_list", {}).then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        setCoinListData(resp.data);
      }
    });
  };

  const CryptoCoinDataHtml = () => {
    const html = [];
    html.push(<option value="select">Select</option>);
    coinListData.map(function (value, i) {
      html.push(<option value={value.id}>{value.short_name}</option>);
    });

    return (
      <select
        className="form-control"
        name="coinType"
        value={selectedCoin}
        onChange={handleValueChange}
      >
        {html}
      </select>
    );
  };

  const meta = {
    title: `C2C Send | ${ProductName}  Exchange`,
    description:
      "High Limit Crypto Exchange For Crypto Currencies Like  TRX, DOGE, BTC, ETH And Many More. Download The App For A Secure Crypto Trading Platform In India.",
    canonical: `https://${ProductName}exchange.com/secure-crypto-exchange-platform`,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Low Cost Crypto App, Crypto Coin Exchange, Best Rate Crypto Exchange, Crypto Platform ",
      },
    },
  };

  return (
    <div>
      <DocumentMeta {...meta}>
        <div>
          <MainHeader />
          <div className="new_inpage  ">
            <div className="container">
              <div className="row">
                <div className="col-md-6 m-auto">
                  <div className="stepper-wrapper mb-4 mt-3">
                    <div
                      className={
                        mainActiveTab === "send_to"
                          ? "stepper-item active"
                          : "stepper-item completed"
                      }
                    >
                      <div className="step-counter">1</div>
                      <div className="step-name">Send TO</div>
                    </div>
                    <div
                      className={
                        mainActiveTab === "send_to" ||
                        mainActiveTab === "enter_amount"
                          ? "stepper-item active"
                          : "stepper-item completed"
                      }
                    >
                      <div className="step-counter">2</div>
                      <div className="step-name">Enter Amount</div>
                    </div>
                    <div
                      className={
                        mainActiveTab === "send_to" ||
                        mainActiveTab === "enter_amount" ||
                        mainActiveTab === "confirm_payment"
                          ? "stepper-item active"
                          : "stepper-item completed"
                      }
                    >
                      <div className="step-counter">3</div>
                      <div className="step-name">Confirm Payment</div>
                    </div>
                  </div>
                  {mainActiveTab === "send_to" && (
                    <div className="white_box ">
                      <div className="text-center ">
                        <h3>Send To</h3>
                        <p>
                          Send Crypto to users Artha Fx via email, phone number,
                          Pay ID.
                        </p>
                      </div>
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              activeTab === "Email" ? "active" : ""
                            }`}
                            data-toggle="tab"
                            href="#Email"
                            onClick={() => handleTabChange("Email")}
                          >
                            Email
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              activeTab === "Phone" ? "active" : ""
                            }`}
                            data-toggle="tab"
                            href="#Phone"
                            onClick={() => handleTabChange("Phone")}
                          >
                            Phone
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              activeTab === "PayID" ? "active" : ""
                            }`}
                            data-toggle="tab"
                            href="#PayID"
                            onClick={() => handleTabChange("PayID")}
                          >
                            Pay ID
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content pt-4 pt-md-4">
                        <div
                          id="Email"
                          className={`tab-pane ${
                            activeTab === "Email" ? "active" : "fade"
                          }`}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Recipient’s email"
                            name="email"
                            onChange={handleValueChange}
                            value={inputVal}
                          />
                          <button
                            type="button"
                            className="btn btn_man mt-4 w100"
                            onClick={() => nextStep("enter_amount", "email")}
                          >
                            Continue
                          </button>
                        </div>
                        <div
                          id="Phone"
                          className={`tab-pane ${
                            activeTab === "Phone" ? "active" : "fade"
                          }`}
                        >
                          <input
                            type="text"
                            className="form-control"
                            name="phone"
                            onChange={handleValueChange}
                            placeholder="Phone"
                            value={inputVal}
                          />
                          <button
                            type="button"
                            className="btn btn_man mt-4 w100"
                            onClick={() => nextStep("enter_amount", "phone")}
                          >
                            Continue
                          </button>
                        </div>
                        <div
                          id="PayID"
                          className={`tab-pane ${
                            activeTab === "PayID" ? "active" : "fade"
                          }`}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Pay ID"
                            onChange={handleValueChange}
                            name="payId"
                            value={inputVal}
                          />
                          {console.log("inpuVal", inputVal)}
                          <button
                            type="button"
                            className="btn btn_man mt-4 w100"
                            onClick={() => nextStep("enter_amount", "payid")}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {mainActiveTab === "enter_amount" && (
                    <div className="white_box ">
                      <div className="text-center ">
                        <h3>Enter Amount</h3>
                      </div>
                      <div className="form-group">
                        <label className="mb-0">Send to</label>
                        <div className="relative">
                          <input
                            type="text"
                            className="form-control pr-5"
                            name="basefiled"
                            disabled
                            value={inputVal}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="mb-0">Currency</label>
                        {CryptoCoinDataHtml()}
                      </div>
                      <div className="form-group">
                        <label className="mb-0">Amount</label>
                        <div className="relative">
                          <input
                            type="number"
                            className="form-control pr-5"
                            name="amount"
                            value={amount}
                            placeholder="Emter amount"
                            onChange={handleValueChange}
                            onKeyPress={handleKeyPress}
                          />
                          <span className="absolute_btc p-2">
                            {selectedCoinName}
                          </span>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="mb-0">Remark (Optional)</label>
                        <div className="relative">
                          <input
                            type="text"
                            className="form-control pr-5"
                            name="remark"
                            value={remark}
                            placeholder="Remark (Optional)"
                            onChange={handleValueChange}
                          />
                        </div>
                      </div>
                      <div className="tc">
                        <button
                          className="btn btn_man fs14 mr-4"
                          onClick={() => nextStepChange("send_to")}
                        >
                          Privious
                        </button>
                        <button
                          className="btn btn_man fs14"
                          onClick={() => nextStepChange("confirm_payment")}
                          disabled={disableAmountBtn}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  )}
                  {mainActiveTab === "confirm_payment" && (
                    <div className="white_box ">
                      <div className="text-center ">
                        <h3>Confirm Payment</h3>
                      </div>
                      <div className="form-group">
                        <label className="mb-0">Send to</label>
                        <div className="relative">
                          <input
                            type="text"
                            className="form-control pr-5"
                            name="basefiled"
                            disabled
                            value={inputVal}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="mb-0">Amount</label>
                        <div className="relative">
                          <input
                            type="text"
                            className="form-control pr-5"
                            name="amount"
                            value={`Payee Recives`}
                            placeholder="Emter amount"
                            disabled
                          />
                          <span className="absolute_btc p-2">
                            {amount} {selectedCoinName}
                          </span>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="mb-0">Payment Method</label>
                        <div className="relative">
                          <div className="form-control">Wallet</div>
                          <div>
                            Current {selectedCoinName} Balance:-{" "}
                            {balanceResp.wallet}
                          </div>
                        </div>
                      </div>
                      <div className="tc">
                        <button
                          className="btn btn_man fs14 mr-4"
                          onClick={() => nextStepChange("enter_amount")}
                        >
                          Privious
                        </button>
                        <button
                          className="btn btn_man fs14"
                          onClick={() => confirmPay()}
                        >
                          Pay
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Modal
              className="trasfer_popop"
              show={is2FaModelOpen}
              backdrop="static"
              onHide={closeModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>Two Factor Authentication</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={verifyCode}>
                  <div className="form-group">
                    <label className="fw-500">Enter Auth Code</label>
                    <input
                      type="text"
                      className="form-control"
                      name="vcode"
                      placeholder="Enter Code"
                      value={vcode}
                      onChange={handleValueChange}
                    />
                  </div>
                  <div className="form-group">
                    <button onClick={verifyCode} className="btn btn_man w100px">
                      Verify
                    </button>
                    <p style={{ color }}>{message} </p>
                  </div>
                </form>
              </Modal.Body>
            </Modal>

            <Modal
              className="trasfer_popop"
              show={isOtpModelOpen}
              backdrop="static"
              onHide={closeModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>Enter OTP</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={verifyCode}>
                  <div className="form-group">
                    <label className="fw-500">Enter C2C OTP</label>
                    <input
                      type="text"
                      className="form-control"
                      name="c2cOtp"
                      placeholder="Enter Code"
                      value={c2cOtp}
                      onChange={handleValueChange}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      onClick={verifyC2CCode}
                      className="btn btn_man w100px"
                      disabled={otpBtnDisabled}
                    >
                      Verify
                    </button>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </div>
        </div>
        <Footer />
        <ToastContainer />
      </DocumentMeta>
    </div>
  );
};

export default Send;
