import React, { Component } from "react";
import { baseUrl } from "../Common/BaseUrl";
import axios from "axios";

class TradingViewChart extends Component {
  constructor(props) {
    console.log("props => ",props);
    super(props);
    this.chartContainerRef = React.createRef();
    this.tvWidget = null; // Initialize a reference to the TradingView widget
    this.theam = localStorage.getItem("theme") === "true" ? "dark" : "light";
    this.operation = "Edit";
    this.temp = 5;
    this.allIntervals = { 1: "1m", 3: "3m", 5: "5m", 15: "15m", 30: "30m", 60: "1h", 120: "2h", 240: "4h", 360: "6h", 480: "8h", 720: "12h", "1d": "1D", "3d": "3D", "1w": "1W", "1M": "1M" };

    this.defaultSelectedIntervals = { 1: "1m", 3: "3m", 5: "5m", 30: "30m", 60: "1h", 120: "2h", 240: "4h", 360: "6h", 480: "8h", 720: "12h", "1d": "1D", "3d": "3D", "1w": "1W", "1M": "1M" };

    if (localStorage.getItem("selectedInterval") != null) {
      this.defaultSelectedIntervals = JSON.parse(localStorage.getItem("selectedInterval"))      
    }else {
      localStorage.setItem("selectedInterval", JSON.stringify(this.defaultSelectedIntervals));
    }

  }

  componentDidMount() {
    if (localStorage.getItem("theme") === "true") {
      this.theam = "dark";
    } else {
      this.theam = "light";
    }

    // if (localStorage.getItem("exType") == "binance") {
    // } else {
    //   this.initChart();
    // }
    this.checkExchange()
  }

  checkExchange = () => {
    const Url = window.location.pathname.split("/");

    let sendCoinData = {
      firstCoin: Url[2],
      secondCoin: Url[3],
    };

    axios
      .post(baseUrl + "/frontapi/checkpair", sendCoinData)
      .then((respData) => {
        var apiResp = respData.data;
        var apiRespData = apiResp.data;
        if (apiRespData.pairId) {
          let sendCoinPair = {
            pair_id: apiRespData.pairId,
          };
          axios
            .post(baseUrl + "/frontapi/checkpairexchange", sendCoinPair)
            .then((respData) => {
              console.log(respData);
              this.initChart();
              var apiExchangeResp = respData.data;
              if (apiExchangeResp.success === true) {
                localStorage.setItem("exType", apiExchangeResp.data.exchange);
              } else {
                localStorage.setItem("exType", "notExchange");
              }
            }).catch((e)=>{
              console.log("logs: ",e);
            })
        }
      });
  };

  componentDidUpdate(prevProps) {
    if (localStorage.getItem("theme") === "true") {
      this.theam = "dark";
    } else {
      this.theam = "light";
    }
    if (this.tvWidget) {
      this.tvWidget.onChartReady(() => {
        this.tvWidget.changeTheme(this.theam);
      });
    }
    const { params } = this.props;
    if (
      params.firstCoin !== prevProps.params.firstCoin ||
      params.secondCoin !== prevProps.params.secondCoin
    ) {
      this.updateSymbol();
    }
  }

  initChart() {
    console.log("this.theam", this.theam);
    const { params } = this.props;
    const pairName = params.firstCoin + params.secondCoin;
    // Initialize the TradingView widget with the initial symbol
    console.log("pairName", window);

    this.tvWidget = new window.TradingView.widget({
      fullscreen: false,
      symbol: pairName,
      interval: "5",
      container: this.chartContainerRef.current,
      height: 400,
      width: "100%",
      //   custom_css_url:"/css/TradingView.css",
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
        baseUrl + "/frontapi"
      ),
      library_path: "/charting_library/",
      locale: "en",

      disabled_features: ["use_localstorage_for_settings"],
      enabled_features: ["study_templates"],
      charts_storage_url: "",
      charts_storage_api_version: "1.1",
      client_id: "om",
      user_id: "public_user_id",
      theme: this.theam,
      timezone: "Asia/Kolkata",
      overrides: {
        "mainSeriesProperties.style": 8,
      },
      drawings_access: {
        type: "black",
        tools: [
          {
            name: "Brush",
          },
          {
            name: "Rectangle",
          },
        ],
      },
    });
    this.tvWidget.onChartReady(() => {
      console.log("onChartReady=> ");
      this.updateSymbol();
      this.updateResolution(this.temp)
      // this.checkTheamChange();
    });
  }

  toggleChartTheme() {
    this.initChart();
  }

  updateSymbol() {
    // Update the symbol property of the existing widget instance
    if (this.tvWidget) {
      const { params } = this.props;
      const pairName = params.firstCoin + params.secondCoin;
      // console.log("pairNa,me", pairName);
      this.tvWidget &&
        this.tvWidget.setSymbol(pairName, "5", () => {
          console.log("Symbol changed successfully");
        });
    }
  }

  updateResolution = (newVal) => {
    console.log(newVal);
    // Update the symbol property of the existing widget instance
    if (this.tvWidget) {
      this.temp = newVal;
      this.tvWidget.activeChart().setResolution(newVal, () => {
        console.log("resolution changed successfully");
      });
    }
  }

  changeOperation = (val) => {
    this.operation = val
  }

  checkTheamChange() {
    if (this.tvWidget) {
     // const currentThema = this.tvWidget.getTheme();
      // console.log("somestorage", currentThema);
    }
  }
  showModal = () => {
    return (
      <div className="dropdown chart_dropdown">
        <button type="button" className="dropdown-toggle buttonchart" data-toggle="dropdown">  </button>
        <div className="dropdown-menu p-3">
          <div className="d-flex pb-1">
            Select Intervals
            {(this.operation === "Edit") ? <span className="ml-auto color1 cursor-pointer" onClick={() => this.changeOperation("Done")}>Edit</span> :
              <span className="ml-auto color1 cursor-pointer" onClick={() => this.changeOperation("Edit")}>Done</span>}
          </div>
          <div className="chart_labes">
            <label className="ch_l ch_active"> Time <i className="fa fa-check-circle"></i></label>
            {Object.keys(this.allIntervals).map((val) =>

              <label className={"ch_l " + (this.defaultSelectedIntervals[val] ? "ch_active" : "")} onClick={() => this.selectInterval(val)}>{this.allIntervals[val]} {(this.operation === "Done") ? <i className="fa fa-close"></i> : <i className="fa fa-check-circle" ></i>} </label>
            )}
          </div>
        </div>
      </div>
    )
  }

  showIntervalList = () => {
    // console.log(JSON.parse(localStorage.getItem("selectedInterval")));
   // if (localStorage.getItem("selectedInterval") !== null) {
  // console.log(this.defaultSelectedIntervals);
      let valueObj = this.defaultSelectedIntervals
     // JSON.parse(localStorage.getItem("selectedInterval"));
      return (
          
        
          Object.keys(valueObj).map(key => {
            return (
              <button className="buttonchart" onClick={() => this.updateResolution(key)} style={{ cursor: "pointer", border: (this.temp == key) ? "1px blue solid" : "" }}>{valueObj[key]}</button>
            );
          })
       

      );
   // }

  }
  selectInterval = (value) => {
    if (this.operation === "Done") {
      // console.log(value);
      let temp = JSON.parse(localStorage.getItem("selectedInterval"));
      // console.log(this.temp);
      let ui = Object.keys(temp).find(key => key === value);
      //  console.log(ui);
      if (ui) {
        delete temp[ui]
      } else {
        let val = Object.entries(this.allIntervals).find(([key, v]) => key === value);
        console.log(val);
        temp[value] = val[1];
      }
      //this.temp[Object.keys(value)[0]] = Object.values(value)[0]
      // console.log(this.temp);
      this.defaultSelectedIntervals = temp;
      localStorage.setItem("selectedInterval", JSON.stringify(temp))
    }
  }
  componentWillUnmount() {
    if (this.tvWidget) {
      this.tvWidget.remove();
    }
  }

  render() {
    return (
      <>

      <div className="day-list"> 
        {this.showModal()}
        <div className="day-list2">  {this.showIntervalList()}</div>
        </div>
        <div ref={this.chartContainerRef} id="tv_chart_container"></div>
      </>
    );
  }
}

export default TradingViewChart;
