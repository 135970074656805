import React, { useEffect, useState, useRef } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const GoogleLoginButton = ({ onLoginSuccess, baseUrl, redirectUrl }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [state, setState] = useState({ referal_code: "" });
  const { t } = useTranslation();
  const [referedUser, setReferedUser] = useState({});
  const [errorState, setErrorState] = useState({});

  const modalhandle = (val) => {
    setModalOpen(val)
  }
  const googleSubmit = () => {
    setReferedUser({})
    setErrorState({});
    setState({ ...state, referal_code: "" })
    console.log("google submit");
    // setModalOpen(true)
    //googleLogin()
  }
  const handleInput = (e) => {
    let errorTemp = {};
    console.log(e.target.name);
    console.log(e.target.value);
    setState({ ...state, [e.target.name]: e.target.value })
    if (e.target.value) {
      if (e.target.value.length >= 7) {
        console.log("api")
        checkReferal(e.target.value)
      } else {
        errorTemp.referal_code = "Entered Sponser is not valid."
      }
    }
    setErrorState(errorTemp)
  }

  const checkReferal = (value) => {
    setReferedUser({})
    setErrorState({});
    let sendData = { refer_id: value }
    console.log(sendData)
    axios.post(baseUrl + "/frontapi/check_valid_referal", sendData).then((res) => {
      console.log("res=> ", res);
      if (res.data.status) {
        setReferedUser(res.data.data[0])
      } else {
        setErrorState({ ...errorState, referal_code: res.data.message })
      }
    }).catch((e) => {
      console.log("error=> ", e);
    })
  }

  const submit_referal = () => {
    let sendData = { referal_id: state.referal_code }
    console.log(sendData)
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/referral-registration", sendData, config).then((res) => {
      console.log("res=> ", res);
      if (res.data.status) {
        toast.success(res.data.message);
        window.location.href = redirectUrl;
      } else {
        toast.error(res.data.message);
      }

    }).catch((e) => {
      console.log("error=> ", e);
    })
  }

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const data = {
        token: tokenResponse.code,
        referal_code: state.referal_code
      };
      try {
        const userInfo = await axios.post(
          baseUrl + "/frontapi/google-login",
          data
        );
        console.log("userInfo", userInfo);
        // Assuming userInfo.data.data is an array and might have one or more items
        if (userInfo.data && userInfo.data.data.length > 0) {
          localStorage.setItem("id", userInfo.data.data[0].id);
          localStorage.setItem("mobile", userInfo.data.data[0].mobile);
          localStorage.setItem("token", userInfo.data.token);
          toast.success("Login Successfully");
          //  setModalOpen(true)
          if (userInfo.data.newUser) {
            setModalOpen(true)
          } else {
            window.location.href = redirectUrl;
          }
        }
      } catch (error) {
        console.error("Login error:", error);
        toast.error("Login failed");
      }
    },
    onError: (errorResponse) => console.log(errorResponse),
    flow: "auth-code",
  });



  const continue_google = () => {
    if (state.referal_code != "") {
      if (Object.keys(errorState).length == 0) {
        submit_referal()
      } else {
        toast.error("Invalid Referal Code");
      }
    } else {
      setErrorState({ ...errorState, referal_code: "Please Enter Referral" })
    }
  }

  const skip_referral = () => {
    // console.log("SKIP");
    window.location.href = redirectUrl;
  }

  return (
    <>
      <button
        className="btn w100 btn_border"
        type="submit"
        onClick={() => googleLogin()}
      >
        <img src={"/assets/img/google.svg"} alt="" className="google_icon mr-2" />{" "}
        {t("continue_with_google")}{" "}
      </button>
      <Modal
        fullscreen={true}
        show={modalOpen}
        onHide={() => modalhandle(false)}
        keyboard={false}
      >
        <Modal.Header>
          <h2>
            Referral code
          </h2>
          <Link to="#" className="a_flex ml-auto" onClick={() => modalhandle(false)}>
            <i className="fa fa-close mr-2"></i>{t("close")}
          </Link>
        </Modal.Header>
        <Modal.Body>

          <>
            <div className="container container2">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <label className="fw-500">{t("Referral code")}</label>
                  <input type="text" className="form-control" name="referal_code" placeholder="Enter Referral code" value={state.referal_code} onChange={(e) => handleInput(e)} />

                  {(errorState.referal_code) ?
                    <span style={{ color: "red" }}>{errorState.referal_code}</span>
                    : (referedUser?.id) ?
                      <span style={{ color: "green" }}>Valid Code</span> : ""}
                </div>
                <div className="col-md-12 col-lg-12 mt-3">
                  <button onClick={() => continue_google()} className=" btn btn_man ">Continue</button>

                  <button onClick={() => skip_referral()} className=" btn btn_man ml-2">Skip</button>
                </div>
              </div>
            </div>
          </>

        </Modal.Body>
      </Modal>
    </>
  );
};

export default GoogleLoginButton;
